import { useState, startTransition } from "react";
import { useRouter } from "next/router";
import { ICertificateData } from "src/api/server/wf-detail.types";

export const useCertificate = (certificates: ReadonlyArray<ICertificateData>) => {
    const router = useRouter();
    const searchTerm = ((router.query.searchTerm ?? router.query.isin) as string)?.trim().toUpperCase();

    const getCertificate = (isin?: string) => getCertificateByIsin(isin) ?? getFirstLicensedCertificate() ?? getFirstOrDefaultCertificate();
    const getCertificateByIsin = (isin?: string) => certificates.find(certificate => certificate.isin === isin);
    const getFirstLicensedCertificate = () => certificates.find(certificate => certificate.isLicensed);
    const getFirstOrDefaultCertificate = () => (certificates.length ? certificates[0] : ({} as ICertificateData));

    const [selectedCertificate, setSelectedCertificate] = useState<ICertificateData>(getCertificate(searchTerm));

    const updateSelectedCertificate = (isin: string) => {
        startTransition(() => {
            setSelectedCertificate(getCertificate(isin));
        });
    };

    return {
        selectedCertificate,
        updateSelectedCertificate,
    };
};
