import { useRouter } from "next/router";
import { scrollIntoView } from "src/utils/dom";
import { scrollToTheTop } from "src/utils/dom-util";
import { EnumJumpMarkSection } from "./consts";
import { useJumpMarkAnchorRefs } from "./jump-mark-anchor-refs-provider";
import { getJumpMarkHash } from "./utils";

export const useJumpToSection = () => {
    const router = useRouter();
    const { getRef, setScrollName } = useJumpMarkAnchorRefs();

    return (id: EnumJumpMarkSection) => {
        const element = getRef(id);

        setScrollName(id);
        if (element) {
            // we remove the id, set the hash and then add the id again afterwards to avoid non-smooth scrolling
            element.removeAttribute("id");
            window.location.hash = getJumpMarkHash(id);
            element.setAttribute("id", id);

            scrollIntoView(element, { behavior: "smooth" }).then(() => {
                setScrollName(null);
            });
        } else {
            // this is the case for the overview section where we want to scroll to the top
            scrollToTheTop();
            router.push(window.location.pathname).then(() => {
                setScrollName(null);
            });
        }
    };
};
